.input-image-holder {
    border: 1px dashed rgb(255, 94, 0);
    width: 100%;
    padding-top: 100%;
    position: relative;

    i {
        position: absolute;
        display: block;
        top: calc(50% - 0.5em);
        left: calc(50% - 8px);
        color: rgb(255, 94, 0);
    }

    input {
        opacity: 0;
        background-color: red($color: #000000);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.input-toggle-button {
    border: 1px solid #ddd;
    background: #ddd;
    border-radius: 12px;
    padding: 4px;
    box-sizing: border-box;
    height: 22px;
    width: 44px;
    position: relative;
    transition: all 0.4s;
    overflow: hidden;

    .toggle-button {
        background: #fff;
        display: block;
        border: 1px solid #ddd;
        border-radius: 9px;
        top: 1px;
        left: 1px;
        width: 18px;
        height: 18px;
        position: absolute;
        transition: all 0.2s;
        box-shadow: 1px 1px 4px #aaa;
    }

    &.active {
        background-color: #ff8f4f;

        .toggle-button {
            // border: 1px solid #fff;
            left: 22px;
            // right: 1px;
        }
    }
}