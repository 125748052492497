$weekday: (weekday0: rgb(255, 190, 190),
    weekday1:rgb(255, 254, 190),
    weekday2:rgb(255, 201, 234),
    weekday3:rgb(190, 255, 201),
    weekday4:rgb(255, 214, 190),
    weekday5:rgb(190, 245, 255),
    weekday6:rgb(215, 190, 255),
);

@for $i from 0 through 6 {
    $weekname: 'weekday#{$i}';

    .has-head-#{$weekname} {
        position: relative;

        &:before {
            content: ' ';
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 6px;
            background: darken(map-get($weekday, $weekname), 10%);
        }
    }

    .has-tail-#{$weekname} {
        position: relative;

        &:before {
            content: ' ';
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 6px;
            background: darken(map-get($weekday, $weekname), 10%);
        }
    }
}