$main-color: #E1623B;
$main-active-color: #d86501;

@import './Theme.scss';

@mixin linear-gradient($direction, $color-stops...) {
	background: $color-stops;
	// background: nth(nth($color-stops, 1), 1);
	// background: -webkit-linear-gradient(legacy-direction($direction), $color-stops);
	// background: linear-gradient($direction, $color-stops);
}

html {
	scroll-behavior: smooth;
}

html,
body {
	font-family: 'Sukhumvit Set', 'Prompt', Arial, Helvetica, sans-serif;
	max-width: 100%;
	font-size: 14px;
}

.hilight {
	font-weight: bold;
	color: $main-color;
}

.App {}

.App-logo {
	animation: App-logo-spin infinite 20s linear;
	height: 40vmin;
	pointer-events: none;
}

.App-header {
	background-color: $main-color;
	color: #fff;
	min-height: 60px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.button {
	line-height: 1;

	i.fa {
		padding-right: 5px;
	}
}

.header-nav {
	background-color: $main-color;
	@include linear-gradient($main-active-color, $main-color);
	// border-bottom: 1px solid #ccc;
	// box-shadow: 0 0px 6px rgba($color: #000000, $alpha: 0.8);
	border-radius: 0 0 12px 12px;
	color: #FFF;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	font-size: 1.4em;
	height: 60px;
	padding-top: 5px;
	z-index: 20;
	line-height: 30px;
	font-weight: bolder;
	overflow: hidden;
	word-break: keep-all;
	white-space: nowrap;

	.logo {
		height: 40px;
	}

	a {
		color: #FFF;
	}

	i {
		padding-right: 5px;
	}
}


.is-active {
	border-bottom: 4px solid $main-color;

	margin-bottom: -4px;
}

.thumbnail-image {
	width: 100%;
	padding-bottom: 100%;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	margin-bottom: 5px;
	border-radius: 12px;
}

/* Margin ============================ */
.m0 {
	margin: 0;
}

@for $i from 0 through 4 {
	$margin: $i * 5;

	.m#{$margin} {
		margin: $margin * 1px !important;
	}

	.mt#{$margin} {
		margin-top: $margin * 1px !important;
	}

	.mb#{$margin} {
		margin-bottom: $margin * 1px !important;
	}

	.ml#{$margin} {
		margin-left: $margin * 1px !important;
	}

	.mr#{$margin} {
		margin-right: $margin * 1px !important;
	}

	.mh#{$margin} {
		margin-left: $margin * 1px !important;
		margin-right: $margin * 1px !important;
	}

	.mv#{$margin} {
		margin-top: $margin * 1px !important;
		margin-bottom: $margin * 1px !important;
	}
}

/* Padding ============================ */
.p0 {
	padding: 0;
}

@for $i from 0 through 4 {
	$padding: $i * 5;

	.p#{$padding} {
		padding: $padding * 1px !important;
	}

	.pt#{$padding} {
		padding-top: $padding * 1px !important;
	}

	.pb#{$padding} {
		padding-bottom: $padding * 1px !important;
	}

	.pl#{$padding} {
		padding-left: $padding * 1px !important;
	}

	.pr#{$padding} {
		padding-right: $padding * 1px !important;
	}

	.ph#{$padding} {
		padding-left: $padding * 1px !important;
		padding-right: $padding * 1px !important;
	}

	.pv#{$padding} {
		padding-top: $padding * 1px !important;
		padding-bottom: $padding * 1px !important;
	}
}

.rich-menu-layout {
	width: 200px;
	height: 135px;
	margin: 10px;
	display: block;
	float: left;
	background-repeat: none;
	background-position: center;
	background-size: 100% 100%;

	&.layout-c {
		height: 66px;
	}

	&:hover,
	&.active {
		border: 4px solid orange;
	}

	.columns {
		padding: 0;
		margin: 0;

		&.r1 {
			height: 100%;
		}

		&.r2 {
			height: 50%;
		}

		&.r3 {
			height: 33.33%;
		}

		&.r4 {
			height: 25%;
		}

		&.r5 {
			height: 20%;
		}

		.column {
			background: rgba($color: #000000, $alpha: 0.5);
			padding: 6px;
			margin: 0;
			color: #fff;
			font-size: 0.8em;
			font-weight: bolder;

			&:nth-child(2n + 1) {
				background: rgba($color: #000000, $alpha: 0.8);
			}
		}

		&:nth-child(2n + 1) {
			.column {
				background: rgba($color: #000000, $alpha: 0.8);

				&:nth-child(2n + 1) {
					background: rgba($color: #000000, $alpha: 0.5);
				}
			}
		}
	}
}

a {
	color: $main-color;
}

.complete-payment {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	border: 12px solid $main-color;
	padding: 40px;
	text-align: center;
	font-size: 1.2em;
	color: $main-color;

	small {
		color: #888;
		font-size: 0.8em;
	}

	img {
		width: 60%;
		max-width: 200px;
	}
}

.item-editor {
	.thumbnail {
		height: 200px;
		width: auto;
		display: block;
	}

	table .thumbnail {
		height: 80px;
	}
}

.coupon-wrapper {
	position: relative;

	.coupon-button {
		position: absolute;
		right: 0;
		top: 0;
		border: 0;
		background: orange;
		height: 100%;
		width: 60px;
		color: #fff;
		border-radius: 0 5px 5px 0;
	}
}

// Firebase Sign in
#firebaseui-auth-container .mdl-card {
	border-radius: 5px;
	margin: 20px auto;
	max-width: 400px;
	padding: 0 0 20px;
	text-align: center;
	box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.2);
	overflow: hidden;

	@media only screen and (max-width: 767px) {
		margin: 0;
		height: 100vh;
		width: 100vw;
		border-radius: 0;
	}

	h1 {
		font-weight: bold;
		padding: 8px 0;
		background: $main-color;
		color: #fff;
	}

	.firebaseui-card-content {
		padding: 20px 40px;
		font-size: 14px;

		label {
			line-height: 32px;
			display: block;
			width: 100%;
			text-align: left;
			font-weight: bolder;
			border-radius: 5px;
		}

		input {
			font-size: 14px;
			height: 32px;
			line-height: 32px;
			margin: 0;
			display: block;
			width: 100%;
			padding: 0 10px;
			border-radius: 5px;
		}
	}

	button {
		height: 32px;
		line-height: 32px;
		font-size: 14px;
		border: 0;
		background: $main-color;
		color: #fff;
		padding: 0 10px;
		margin: 5px;
		width: 100px;
		font-weight: bolder;
	}
}

// End::Firebase Sign in

.rich-menu-description {
	text-align: left;
}

.rcs_slideCon .itemWrapper img {
	display: block;
	height: 100%;
	width: auto;
	max-width: inherit;
}

.rcs_buttonWrapper {
	z-index: 10 !important;
}

.order-editor {
	label {
		font-size: 1.2em;
		font-weight: bolder;
		display: block;
	}
}

.ticket.status {
	background: rgb(0, 132, 203);
	color: #fff;
	padding: 4px 9px;
	border-radius: 4px;
	font-size: 0.8em;
	font-weight: bolder;
	margin-bottom: 5px;
	text-align: center;

	&.ordered {
		background: rgb(0, 179, 179);
	}

	&.picked {
		background: green;
	}
}

.toggle-button {
	.column {
		padding: 0;
	}

	.is-active {
		background-color: $main-color;
		color: #fff;
		border: inherit;
		box-shadow: inherit;
	}
}

.order.status {
	background: rgb(0, 132, 203);
	color: #fff;
	padding: 4px 9px;
	border-radius: 4px;
	font-size: 0.8em;
	font-weight: bolder;
	margin-bottom: 5px;
	text-align: center;

	&.submitted {
		background: rgb(0, 132, 203);
	}

	&.paid {
		background: rgb(0, 179, 179);
	}

	&.ontheway {
		background: rgb(0, 179, 179);
	}

	&.delivered {
		background: green;
	}

	&.pending {
		background: lightgray;
	}

	&.cancel {
		background: red;
	}

	&.preorder {
		background: $main-color;
	}
}

.register-application {
	font-family: 'Sukhumvit Set', 'Prompt', Arial, Helvetica, sans-serif;
	background: url('/shop-top.png') repeat-x top center;

	a,
	.button {
		font-family: 'Sukhumvit Set', 'Prompt', Arial, Helvetica, sans-serif;
	}

	.container {
		max-width: 900px;
		margin: 0 20px 20px;
		padding-top: 100px;
	}

	@media screen and (min-width: 1000px) {
		.container {
			margin: 0 auto 20px;
		}
	}

	hr {
		border-top: 1px dashed #ccc;
		background: none;
		// border: 0;
		// background: 0;
		// margin: 10px 0;
	}

	.label {
		color: #5b5b5b;
	}

	.title {
		color: #5b5b5b;
	}

	.subtitle {
		color: #5b5b5b;
	}

	.help {
		color: #888;
	}

	.field.is-horizontal {
		padding-bottom: 15px;
	}

	.packages {
		.card {
			border-radius: 8px;
			overflow: hidden;
			position: relative;

			transition-duration: 0.1s;

			&:hover,
			&.selected {
				transform: scale(1.1);
				z-index: 9999;
			}
		}

		.card-content {
			padding: 0;

			.content {
				font-size: 0.8em;
				padding: 0 15px 15px;
			}
		}

		.media {
			.media-content {
				overflow: hidden;
				min-height: 3.4em;
			}

			background: #ffdb6e;
			padding: 15px;
			margin-bottom: 10px;

			.title,
			.subtitle {
				color: $main-color;
				text-align: center;
			}
		}

		.card.selected .media {
			background: $main-color;

			.title,
			.subtitle {
				color: #fff;
				text-align: center;
			}
		}
	}
}

.ordering {
	font-family: 'Sukhumvit Set', 'Prompt', Arial, Helvetica, sans-serif;
	margin: 15px;
	padding-bottom: 80px;

	.complete {
		padding: 20px 10px;
		font-size: 1.2em;

		h1 {
			font-size: 1.5em;
			color: $main-color;
			margin-bottom: 10px;
		}
	}

	input,
	button,
	select,
	textarea {
		font-family: 'Sukhumvit Set', 'Prompt', Arial, Helvetica, sans-serif;
	}

	h1 {
		font-size: 2rem;
	}

	h2 {
		font-size: 1.6rem;
	}

	.button.is-success,
	.button.is-primary {
		background-color: $main-color;

		&:hover {
			background-color: $main-active-color;
		}

		&.is-outlined {
			border-color: $main-color;
			color: $main-color;
			background: none;
		}
	}

	.button.my-location {
		position: fixed;
		z-index: 1000;
		bottom: 110px;
		right: 10px;
		width: 40px;
	}

	.menu-item {
		position: relative;

		@media screen and (max-width:768px) {
			padding-right: 5px;

			&:nth-chld(2n) {
				padding-right: 10px;
				padding-left: 5px;
			}
		}

		&.is-full {
			border-top: 1px solid #ccc;
			padding: 15px;
		}

		.name,
		.name a {
			color: #444;
			font-weight: bolder;
		}

		.price {
			font-weight: bolder;
			color: #444; //$main-color;
		}

		.sale-time {
			position: absolute;
			background: white;
			padding: 4px 10px;
			border-radius: 5px;
			top: 20px;
			left: 20px;
			font-size: 0.8em;
			font-weight: bolder;
			color: $main-color;
		}

		&.text-only {
			.sale-time {
				position: relative;
				top: 0;
				left: 0;
				padding: 0;
				background: none;
			}
		}

		button.add-button {
			background: $main-color;
			width: 24px;
			height: 24px;
			color: #fff;
			line-height: 16px;
			font-size: 16px;
			text-align: center;
			border: 0;
			float: right;
			margin: 0;
			padding: 0;
			margin-top: 5px;
		}
	}

	.selected-item {
		position: fixed;
		left: 0px;
		right: 0px;
		bottom: -1px;

		width: calc(100vw);
		color: #fff;
		background: $main-color;
		padding: 15px;
		padding-bottom: 30px;
		text-align: center;
		border-radius: 0;
		border-radius: 10px 10px 0 0;
		// box-shadow: 1px 1px 8px rgba(0.5, 0.5, 0.5, 0.5);
	}

	.modal-content {
		overflow: auto;
		border-radius: 15px;
	}

	.map-dialogs {
		align-items: flex-end;

		.modal-background {
			background: #fff;
		}

		.modal-content {
			width: 100%;
			max-height: 100vh;
			padding: 0;
			margin: 0;
		}

		.modal-close {
			color: #000;
			margin-top: -5px;

			&:before,
			&:after {
				background-color: #000;
			}
		}
	}

	.summary-dialogs {
		position: relative;
		top: -100px;
		// left: 0;
		// bottom: 0;
		// right: 0;
		background-color: #fff;
		align-items: flex-end;
		// overflow: hidden;

		.summary-wrapper {
			overflow: auto;
			height: 100%;
			max-width: 800px;
			margin: auto;
			padding: 10px;
			// padding-bottom: 120px;
		}

		* {
			transition: all 0.1s ease;
		}

		hr {
			border-top: 1px dashed #ccc;
			background: none;
			// border: 0;
			// background: 0;
			// margin: 10px 0;
		}

		a,
		input,
		textarea,
		select {
			box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
			display: block;
			border-radius: 5px;
			border: 0;
		}

		input[type='checkbox'] {
			border: 2px solid $main-color;
			background: #fff;
			width: 20px;
			height: 20px;
		}

		a {
			// line-height: 15px;
			text-align: left;
			color: $main-color;

			&.location-item {
				padding: 10px 20px;
			}

			.fa {
				line-height: 1.65;
			}
		}

		select:after {
			position: absolute;
			content: '';
			top: 14px;
			right: 10px;
			width: 0;
			height: 0;
			border: 6px solid transparent;
			border-color: $main-color transparent transparent transparent;
		}

		.buttons.has-addons {
			display: flex;

			.button {
				border-radius: 0;
				flex: 1;

				&:nth-child(1) {
					border-right: 0;
				}
			}
		}

		.checkbox {
			position: relative;
			width: 100%;
			padding: 10px;
			border: 1px dashed #ddd;
			border-radius: 10px;
			margin-bottom: 10px;

			.level-right {
				border: 1px solid #ddd;
				background: #fff;
				width: 48px;
				border-radius: 14px;
				position: relative;
				height: 26px;

				i {
					position: absolute;
					left: 1px;
					top: 1px;
					border: 1px solid #eee;
					background: #fff;
					border-radius: 11px;
					width: 22px;
					height: 22px;
					box-shadow: 2px 2px 2px rgba($color: #000000, $alpha: 0.2);
					transition: all 0.1s ease;
				}
			}

			&.active {
				// background-color: $main-color;
				// color: #fff;
				// border: 1px solid $main-color;

				.level-right {
					background: $main-color;
					border-color: $main-color;

					i {
						left: 22px;
						// border-color: orange;
					}
				}
			}

			input {
				position: absolute;
				opacity: 0;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				line-height: 40px;
			}

			div {
				justify-content: left;
			}
		}

		.promotions {
			border: 1px dashed $main-color;
			border-radius: 8px;
			padding: 6px 20px;

			margin-bottom: 10px;

			label {
				margin: 0;
				padding: 0;
			}

			li {
				color: $main-color;
			}

			.inactive {
				text-decoration: line-through;
			}
		}

		.payment-method {
			button {

				&:hover,
				&:active {
					background: none;
				}

				border: 0;
				font-size: 1em;
				// line-height: 1.8;
				// padding: 8px 20px;
				// padding-bottom: px;
				color: $main-color;
				height: 22px;
				padding: 0;

				&.inactive {
					color: inherit;

					i {
						color: lightgray;
					}
				}
			}
		}

		.modal-background {
			background: #fff;
		}

		.modal-content {
			max-height: calc(100vh);
			padding: 10px 0 200px;
		}

		.modal-close {
			color: #000;

			&:before,
			&:after {
				background-color: #000;
			}
		}

		textarea {
			height: 100px;
		}

		label {
			display: block;
			margin-top: 15px;
			margin-bottom: 4px;
		}

		.field {
			// border: 1px solid #ccc;
			// -webkit-box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
			// box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
			max-width: 100%;
			padding: 4px 8px;
			border-radius: 3px;

			label {
				// color: #888;
				// font-size: 0.8em;
				margin: 0;
				margin-bottom: 8px;
			}
		}

		.item-product {
			margin-bottom: 10px;

			.sale-time {
				color: red;
			}

			img.thumbnail {
				width: 60px;
				border-radius: 30px;
			}

			.item-name {
				font-size: 0.9rem;

				.item-option {
					font-size: 0.8rem;
					color: #888;
				}
			}

			.item-note {
				font-size: 0.9em;
			}

			.level {
				margin: 0;
			}

			.item-actions {
				display: flex;
				justify-content: space-between;
				padding-left: 50px;

				.item-quantity {
					display: flex;
				}
			}

			input {
				width: 40px;
				height: 22px;
				line-height: 20px;
				font-size: 14px;
				padding: 0;

				font-weight: bolder;
				text-align: center;
			}

			button.add-button {
				background: transparent;
				width: 20px;
				height: 20px;
				line-height: 10px;
				font-size: 12px;
				text-align: center;
				border: 0;
				margin-left: -2px;
				overflow: hidden;
				margin: 0;
				padding: 0;

				i {
					line-height: 19px;
					font-size: 1em;
				}
			}
		}

		.summary-panel {
			border-radius: 10px;
			box-shadow: 1px 1px 8px rgba(0.5, 0.5, 0.5, 0.2);
			background: rgba($color: #fff, $alpha: 0.95);
			position: fixed;
			left: 10px;
			right: 10px;
			bottom: 30px;
			width: calc(100vw - 20px);
			padding: 15px;
			z-index: 10;
			max-width: 800px;
			margin: auto;

			.level {
				padding: 0;
				margin: 0;
			}
		}
	}

	.option-dialogs {
		justify-content: start;

		.delete::after,
		.modal-close::after,
		.delete::before,
		.modal-close::before {
			background: #fff;
		}

		.modal-background {
			background: #FFF;
		}

		.header-nav {
			z-index: 2000;
		}

		.modal-content {
			z-index: 10;
			background: #fff;
			padding: 70px 20px;

			.item-option {
				margin-bottom: 10px;

				input {
					width: 40px;
					height: 30px;
					font-size: 15px;
					font-weight: bolder;
					text-align: center;
				}

				button.add-button {
					background: $main-color;
					width: 30px;
					height: 30px;
					color: #fff;
					line-height: 14px;
					font-size: 15px;
					text-align: center;
					border: 0;
					margin: 0;
					padding: 0;
				}
			}
		}
	}

	.note-dialogs {

		.modal-close:before,
		.modal-close:after {
			background-color: #000;
		}


		.modal-content {
			background: #fff;
			padding: 20px;
			margin: 0;
			// height: 100vh;
			// width: 100vw;
			// max-height: 100vh;

			textarea {
				border: 0;
				width: 100%;
				height: 150px;
				padding: 10px;
				border-top: 1px dashed #ccc;
			}

			@media screen and (min-width: 600px) {
				max-width: 600px;
				max-height: 400px;

				textarea {
					height: calc(100% - 100px);
				}
			}

			.item-option {
				margin-bottom: 10px;

				input {
					width: 40px;
					height: 30px;
					font-size: 15px;
					font-weight: bolder;
					text-align: center;
				}

				button.add-button {
					background: $main-color;
					width: 30px;
					height: 30px;
					color: #fff;
					line-height: 14px;
					font-size: 15px;
					text-align: center;
					border: 0;
					margin: 0;
					padding: 0;
				}
			}
		}

	}

	.description-dialogs {
		-webkit-animation-duration: 0.4s;
		animation-duration: 0.4s;
		transition-timing-function: ease-in;
		justify-content: start;

		.delete::after,
		.modal-close::after,
		.delete::before,
		.modal-close::before {
			background: #fff;
		}

		.modal-background {
			background: #FFF;
		}

		.header-nav {
			z-index: 2000;
		}

		@media screen and (max-width: 600px) {
			.dialog-footer {
				position: fixed;
				left: 0;
				right: 0;
				bottom: 0;
				width: 100%;
				padding: 0px 10px 20px 5px;
				box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.5);
				background: #fff;
				// @include linear-gradient(#fff, #eee);

				.column {
					padding: 4px !important;
				}
			}
		}


		.modal-content {
			background: #fff;
			margin-top: 60px;
			padding: 20px 20px 120px;

			width: 100%;
			max-width: 500px;

			max-height: 100%;



			bank-account .name {
				font-size: 1.5em;

				a {
					color: #000;
				}
			}

			.column {
				position: relative;
			}

			@media screen and (min-width:768px) {
				max-width: 800px;
				margin-top: 0;
				padding: 70px 20px 100px;

				.column {
					padding: 0 10px !important;
				}
			}

			.thumbnail-image {
				border-radius: 8px;
				margin-bottom: 10px;
			}

			.name {
				font-size: 1.4em;
				font-weight: bold;
				font-size: #888;
			}

			.price {
				font-size: 1em;
				font-weight: bold;
				color: $main-color;
				// position: absolute;
				top: 0;
			}

			.item-options {
				border-top: 1px dashed #ffd1aa;
				padding-top: 6px;
				margin-top: 6px;

				>div:first-child {
					font-size: 1.2em;
					margin-bottom: 0px;

					color: #888;
				}
			}

			.item-option {
				// border-bottom: 1px solid #ddd;
				padding: 0 10px;
				margin: 0px 0;
				line-height: 28px;
				position: relative;
				display: block;

				&:hover {
					background: $main-color;
					color: #fff;
					box-shadow: 1px 1px 4px rgba($color: #000000, $alpha: 0.5);
					border: 0;
					border-radius: 5px;


					div.price {
						color: #fff;
					}
				}

				input {
					// width: 40px;
					// height: 30px;
					position: relative;
					top: 2px;
					font-size: 15px;
					font-weight: bolder;
					text-align: center;
				}

				div.option-name {
					display: inline-block;
					vertical-align: top;
					padding-left: 5px;
					width: calc(100% - 80px);
				}

				div.price {
					position: absolute;
					top: 0;
					right: 10px;
				}

				button.add-button {
					background: $main-color;
					width: 30px;
					height: 30px;
					color: #fff;
					line-height: 14px;
					font-size: 15px;
					text-align: center;
					border: 0;
					margin: 0;
					padding: 0;
				}
			}
		}
	}

	.payment-dialogs {
		.modal-content {
			background: #fff;
			padding: 10px;

			.item-option {
				margin-bottom: 10px;

				input {
					width: 40px;
					height: 30px;
					font-size: 15px;
					font-weight: bolder;
					text-align: center;
				}

				button.add-button {
					background: $main-color;
					width: 30px;
					height: 30px;
					color: #fff;
					line-height: 14px;
					font-size: 15px;
					text-align: center;
					border: 0;
					margin: 0;
					padding: 0;
				}
			}
		}
	}
}

/* Example Styles for React Tags*/
div.ReactTags__tags {
	position: relative;
}

/* Styles for the input */
div.ReactTags__tagInput {
	width: 200px;
	border-radius: 2px;
	display: inline-block;
}

div.ReactTags__tagInput input.ReactTags__tagInputField,
div.ReactTags__tagInput input.ReactTags__tagInputField:focus {
	height: 31px;
	margin: 0;
	font-size: 12px;
	width: 100%;
	border: 1px solid #eee;
}

/* Styles for selected tags */
div.ReactTags__selected span.ReactTags__tag {
	border: 1px solid #ddd;
	background: #eee;
	font-size: 12px;
	display: inline-block;
	padding: 5px;
	margin: 0 5px;
	border-radius: 2px;
}

div.ReactTags__selected a.ReactTags__remove {
	color: #aaa;
	margin-left: 5px;
	cursor: pointer;
}

/* Styles for suggestions */
div.ReactTags__suggestions {
	position: absolute;
	z-index: 9999;
}

div.ReactTags__suggestions ul {
	list-style-type: none;
	box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
	background: white;
	width: 200px;
}

div.ReactTags__suggestions li {
	border-bottom: 1px solid #ddd;
	padding: 5px 10px;
	margin: 0;
}

div.ReactTags__suggestions li mark {
	text-decoration: underline;
	background: none;
	font-weight: 600;
}

div.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
	background: #b7cfe0;
	cursor: pointer;
}

.hint {
	color: #888;
	font-size: 1em;
}

.image-upload-button {
	input[type='file'] {
		display: inline-block;
		border: 1px dashed #ccc;
		// width: 100px;
		height: 100px;
		padding: 0;
		margin: 0;
		position: relative;
		background: #fff;

		&::-webkit-file-upload-button {
			visibility: hidden;
		}

		&::before {
			font-family: 'Font Awesome 5 Free';
			font-weight: 900;
			text-rendering: auto;
			-webkit-font-smoothing: antialiased;
			content: '\f382';
			background: #fff;
			display: inline-block;
			padding: 20px 8px 0;
			outline: none;
			white-space: nowrap;
			-webkit-user-select: none;
			cursor: pointer;
			text-align: center;
			font-size: 2.5em;
			width: 100%;
		}

		&::after {
			position: absolute;
			bottom: 10px;
			content: 'กรุณาเลือกไฟล์';
			display: block;
			background: #fff;
			border-radius: 3px;
			padding: 5px 8px;
			outline: none;
			white-space: nowrap;
			-webkit-user-select: none;
			cursor: pointer;
			width: 100%;
			text-align: center;
			font-size: 1em;
		}
	}

	&.selected {
		height: 100px;
		position: relative;

		div.preview {
			position: absolute;
			text-align: center;
			top: 0;
			bottom: 0;
			width: 100%;
			pointer-events: none;
		}

		input[type='file'] {
			height: 100px;

			&::before {
				content: '';
				display: none;
			}

			&::after {
				content: '';
				width: 100%;
				height: 100%;
				background: none;
			}
		}
	}
}

@import './scss/Helper.scss';
@import './scss/Weekday.scss';

.item-canceled {
	text-decoration: line-through;
}

.is-soldout {
	.thumbnail-image {
		opacity: 0.6 !important;
	}

	.add-button {
		display: none;
	}
}

.amount-wrapper {
	padding: 5px 0 10px;

	input {
		border: 0;
		text-align: center;
		font-size: 1.2em;
		width: 80px;
	}

	button.add-button {
		background: none; //$main-color;
		width: 24px;
		height: 24px;
		border-radius: 50%;
		color: $main-color;
		line-height: 16px;
		font-size: 16px;
		text-align: center;
		border: 0;
		// float: right;
		margin: 0;
		padding: 0;
		margin-top: 5px;
	}
}

.item-option.is-soldout {

	&,
	& .option-name {
		text-decoration: line-through;
	}


	.add-button {
		opacity: 0.6 !important;
		display: inline-block;
	}
}


.item-by-admin {
	font-size: 0.6em;
	text-transform: uppercase;
	background: $main-color;
	color: #fff;
	padding: 1px 8px;
	font-weight: bolder;
	display: inline-block;
	border-radius: 4px;
}

.is-outtime {
	.thumbnail-image {
		opacity: 0.6 !important;
		filter: grayscale(100%);
	}

	.add-button {
		display: none;
	}
}

.soldout {
	background-color: red;
	color: white;
	text-align: center;
	position: absolute;
	padding: 4px 10px;
	border-radius: 5px;
	top: 20px;
	left: 20px;
}

.order-image {
	width: 100%;
	max-width: 400px;
}

.container {
	position: static;
}

.overlay-image {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	text-align: center;
	background-color: rgba($color: #000000, $alpha: 0.5);

	img {
		width: 100%;
		max-width: 400px;
	}
}

.store-application {
	max-width: 800px;
	margin: auto;

	.button.is-primary {
		background-color: $main-color;

		&:hover {
			background-color: $main-active-color;
		}

		&.is-outlined {
			border-color: $main-color;
			color: $main-color;
			background: none;
		}
	}
}

.footer-credit {
	text-align: center;
	font-size: 0.8em;
	color: #aaa;
	padding-top: 10px;
}

.admin-panel {
	padding: 10px 10px 10px;
	margin-bottom: 25px;
	// border: 1px solid #ffe8cf;
	box-shadow: 1px 1px 4px #ccc;
	border-radius: 8px;

	.title {
		font-size: 1.2em;
		font-weight: bold;
		color: #888;
	}
}

.admin-application {
	.title {
		font-weight: bold;
		color: $main-color;
		margin-bottom: 5px;
	}

	.subtitle {
		font-weight: bold;
		color: $main-color;
	}
}

.is-small {
	color: #666;
	font-size: 0.8em;
}

.address-description {
	padding-top: 10px;
	padding-bottom: 10px;

	.column {
		margin-top: 0;
		margin-bottom: 0;

		padding-top: 0;
		padding-bottom: 0;
	}
}

.modal.admin-modal {
	.modal-content {
		background: #fff;
		padding: 20px;
		border-radius: 15px;

		h1.title {
			padding: 10px;
		}

		>div.content {
			padding: 10px 0 0;
		}
	}
}

.dashboard-panel {
	@media screen and (max-width: 768px) {
		.level-left {
			text-align: center;
			padding: 0 20px;
		}

		.button {
			width: 100%;
		}
	}
}

.pac-target-input {
	width: calc(100% - 20px);
	height: 45px;
	padding-left: 16px;
	margin-top: 2px;
	margin-bottom: 100px;
	position: absolute;

	// z-index: 9999;
	position: fixed;
	top: 6px;
	left: 10px;
	right: 10px;
}

.map-deliver-address {
	z-index: 9999;
	position: fixed;
	bottom: 24px;
	left: 15px;
	right: 60px;
	background: #fff;
	padding: 15px;
	border-radius: 5px;
	box-shadow: 1px 1px 4px rgba($color: #000000, $alpha: 0.2);
	font-size: 0.8em;
}

.store-list {
	>div {
		position: relative;

		&.raining::after {
			content: 'ขณะนี้ฝนตกหนักมาก อาจส่งผลต่อเวลาจัดส่ง';
			position: absolute;
			top: calc(50% - 30px);
			height: 60px;
			line-height: 50px;
			left: 0;
			right: 0;
			background-color: rgba($color: #4d4d4d, $alpha: 0.9);
			color: white;
			padding: 5px;
			font-size: 1em;
			text-align: center;
			z-index: 99;
		}

	}

	.closed {

		img {
			background-color: #000;
			opacity: 0.7;
		}

		&::after {
			content: ' ';
			background: url('/close_corner.png') top left no-repeat;
			background-size: 40%;
			position: absolute;
			// top: calc(50% - 30px);
			// height: 60px;
			line-height: 50px;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			// background-color: rgba($color: #ff0000, $alpha: 0.9);
			color: white;
			// padding: 5px;
			font-size: 1em;
			text-align: center;
			z-index: 10;
			pointer-events: none;
		}
	}
}

.bank-account {
	margin-top: 10px;
	padding: 6px 10px;
	border: 1px dashed lightgray;
	border-radius: 10px;

	>div {
		// width: 50%;
		// display: inline-block;
		font-size: 0.9em;

		&.bank {
			color: $main-color;
			width: 100%;
			font-size: 1em;
		}

		&.account-name {
			width: 100%;
		}
	}
}

.pickup-address-item {
	.pickup-name {
		color: $main-color;
	}

	.pickup-address {
		// color: $main-color;
	}
}

.item-editor {
	.notification {
		position: fixed;
		top: 80px;
		left: 20px;
		right: 20px;
		z-index: 9999;
	}
}

@import './scss/Ordering.scss';
@import './scss/Input.scss';
@import './scss/ProductEditor.scss';


.delivery-price-table {

	td,
	th {
		font-size: 0.8em;
		padding: 2px 4px;
	}
}

.delivery-tiers {
	.delivery-tier-row {
		line-height: 30px;
		padding: 10px 0;

		.km-text {
			line-height: 20px;
		}

		span.startkm {
			width: 40px;
			display: inline-block;
			text-align: right;
			font-size: 1em;
			font-weight: bold;
		}

		input[type=number],
		input[type=text] {
			line-height: 30px;
			height: 30px;
			width: 50px;
			margin: 0 10px;
			text-align: right;
			padding: 5px;
		}

		label.checkbox {
			// padding: 10px;
			margin-left: 40px;
			line-height: 30px;

			input {
				margin-right: 4px;
			}

			@media screen and (max-width: 500px) {
				margin-left: 150px;

			}
		}
	}
}

.categories-wrapper {



	// display: flex;
	padding: 4px 0 4px;
	// overflow: auto;
	// height: 50px;

	li {
		padding: 5px 0;
	}

	.category-link {
		transition: all 0.2s;
		background: rgba($color: $main-color, $alpha: 0);
		color: #666;
		font-size: 1.2em;
		line-height: 30px;
		height: 30px;
		padding: 0 20px;
		// margin: 10px 0px;
		// float: left;
		display: block;
		border-bottom: 0;

		&.selected {
			background: $main-color;
			border-radius: 20px;
			color: #FFF;
			// border-bottom: 2px solid $main-color;
		}

		// border-radius: 20px;
	}
}

.category-header {
	font-size: 1.5em;
	line-height: 1.6;

}

.order-view {

	.table td,
	.table th {
		border: 0;
		padding-bottom: 10px;
	}

	.order-status {
		text-align: center;
		font-size: 1.4em;
		padding: 10px 0;
		border: 1px solid;

		&.submitted {
			border-color: rgb(0, 132, 203);
			color: rgb(0, 132, 203);
		}

		&.paid {
			border-color: rgb(0, 179, 179);
			color: rgb(0, 179, 179);
		}

		&.ontheway {
			border-color: rgb(0, 179, 179);
			color: rgb(0, 179, 179);
		}

		&.delivered {
			border-color: green;
			color: green;
		}

		&.pending {
			border-color: lightgray;
			color: lightgray;
		}

		&.cancel {
			border-color: red;
			color: red;
		}

		&.preorder {
			border-color: $main-color;
			color: $main-color;
		}
	}
}


.rating-icon {
	font-size: 2.2em;
	color: #ddd;
	padding: 4px;

	&.active {
		color: #ffd92d;

		&.rating1 {
			color: #ff6c2d
		}

		&.rating2 {
			color: #ff7d2d
		}

		&.rating3 {
			color: #ffb22d
		}

		&.rating4 {
			color: #ffbc2d
		}

		&.rating5 {
			color: #ffd92d
		}
	}
}

.rating-wrapper {
	color: #444;
	text-align: center;
	// line-height: 3;
	font-size: 1rem;
	padding: 20px 0;
	max-width: 300px;
	margin: 10px auto;
	border-radius: 20px;

	h3 {
		font-weight: bold;
		font-size: 1.4em;
		line-height: 1.6;
		margin-bottom: 20px;
	}

	button {
		margin-top: 30px;
	}
}



.checkbox-container {
	display: block;
	position: relative;
	padding-left: 25px;
	margin-bottom: 12px;
	cursor: pointer;
	font-size: 22px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;


	/* Hide the browser's default checkbox */
	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
	}

	/* Create a custom checkbox */
	.checkmark {
		position: absolute;
		top: 0;
		left: 0;
		height: 25px;
		width: 25px;
		background-color: #fff;
		border: 1px solid #ccc;

		/* Create the checkmark/indicator (hidden when not checked) */
		&:after {
			content: "";
			position: absolute;
			display: none;

			left: 9px;
			top: 5px;
			width: 5px;
			height: 10px;
			border: solid $main-color;
			border-width: 0 3px 3px 0;
			-webkit-transform: rotate(45deg);
			-ms-transform: rotate(45deg);
			transform: rotate(45deg);
		}
	}


	/* When the checkbox is checked, add a blue background */
	input:checked~.checkmark {
		background-color: #fff;



		/* Show the checkmark when checked */
		&:after {
			display: block;
		}
	}


	/* On mouse-over, add a grey background color */
	&:hover input~.checkmark {
		background-color: #ccc;
	}
}

.field .extra-options-wrapper {
	border: 1px solid #eee;
	padding: 10px;
	border-radius: 10px;
	margin-bottom: 10px;
}

.field.options .column {
	padding-bottom: 0;
}

.extra-option-item .column:first-child {
	// padding-left: 40px;
}

.extra-title {
	font-weight: bold;
	color: #666 !important;


	.extra-hint {
		font-size: 0.75em;
		color: #888;
	}
}