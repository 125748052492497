.product-options {
    padding: 10px 0px 20px 0px;

    .option-item {
        border: 1px dashed #ccc;
        padding: 10px 20px 15px;
        margin: 0;
        margin-bottom: 10px;
        position: relative;

        .column-image {
            max-width: 100px;
        }

        .button-remove {
            position: absolute;
            right: 0;
            top: 0;
            width: 32px;
        }
    }
}

.delivery-options {
    padding: 0px 0px 20px 0px;

    .option-item {
        position: relative;
        line-height: 40px;
        border-bottom: 1px dashed #ccc;

        &:last-child {
            border-bottom: 0;
        }

        label {
            margin: 0;
        }
    }

    .delivery-price {
        position: absolute;
        text-align: right;
        top: 0;
        right: 55px;
        font-size: 0.8em;
    }

    .delivery-price-input {
        position: absolute;
        text-align: right;
        top: 0;
        right: 55px;
        font-size: 0.8em;
        width: 100px;

        input {
            margin-top: 5px;
            width: 60px
        }
    }

    .delivery-toggle {
        position: absolute;
        top: 9px;
        right: 0;
    }
}