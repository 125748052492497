$main-color: #E1623B;
$main-active-color: #d86501;
$green-color: #00d1b2;

@import '../Theme.scss';



.store-name-section {
    font-size: 1.2em;
    color: #E1623B;
}

.quicksearch {
    position: relative;

    &.front-search {
        padding-bottom: 10px;
    }

    input,
    select {
        height: 30px;
        border-radius: 20px;
        padding-left: 36px;
    }

    >i {
        position: absolute;
        top: 9px;
        left: 10px;
        color: $main-color;
    }

    button {
        position: absolute;
        top: 4px;
        right: 6px;
        border-radius: 20px;
        height: 22px;
        width: 22px;
        line-height: 10px;
        background-color: #aaa;
        border: 0;

        i {
            position: relative;
            color: #fff;
        }
    }
}

.ordering {
    padding-top: 210px;
    margin: 0;
    position: relative;
    overflow: visible;

    @media screen and (min-width: 769px) {
        padding-top: 180px;
    }

    .carts-quantity {
        background-color: red;
        color: #fff;
        border-radius: 10px;
        // width: 20px;
        height: 20px;
        font-size: 0.6em;
        position: absolute;
        top: 5px;
        right: 2px;
        padding: 4px;
        line-height: 14px;
        text-align: center;
    }

    .selected-item {
        .level-left {
            position: relative;
            width: 30px;

            .carts-quantity {
                top: -10px;
                right: -5px;
            }

            i {
                font-size: 20px;
            }
        }
    }

    .description {
        white-space: "pre-wrap";
        padding-top: 5 px;
        line-height: 1.8;
    }

    .item-options,
    .product-note {
        label {
            display: block;
            font-weight: bolder;
            padding-bottom: 4px;
            color: $main-color;
            font-size: 1.1em;
            padding-top: 5px;
        }
    }

    .product-note {
        border-top: 1px dashed #ffd1aa;
        margin-top: 10px;
        padding-top: 6px;

        span {
            font-size: 0.9em;
            color: #888;
        }

        textarea {
            width: 100%;
            height: 100px;
            padding: 8px;
            border-radius: 8px;
            border-color: #CCC;
            margin-top: 8px;
        }
    }

    .footer-zone-wrapper {
        position: fixed;
        bottom: 39px;
        right: 0;
        left: 0;
        height: 50vh;
        background-color: #fff;
        box-shadow: 0px -0px 10px rgba($color: #000000, $alpha: 0.6);
        overflow: auto;
        transition: all 100ms;

        &.hide {
            height: 0;
        }

        .footer-zone-list {
            .footer-zone-item {
                padding: 10px 15px;
                color: #888;
                font-size: 1em;

                .fa {
                    color: $main-color;
                }
            }
        }
    }

    .hub-banner {
        border-radius: 12px;
        padding-bottom: 4px;
    }

    .header-nav {
        .carts-button {
            position: absolute;
            top: 0;
            right: 0;
            line-height: 60px;
            padding: 0 10px;


        }
    }

    .product-quantity {
        color: #f14668;
        // font-size: 1.2em;
        font-weight: bolder;
    }

    .notice-cart-added {
        position: absolute;
        top: 60px;
        @include linear-gradient(#fff, #eee);
        z-index: 10000;
        width: calc(100% - 20px);
        font-size: 1.2em;
        padding: 10px;
        color: $main-color;
        margin: 10px;
        border: 1px solid #eee;
        border-radius: 8px;
        box-shadow: 2px 2px 4px rgba($color: #000000, $alpha: 0.6);

        i {
            margin-right: 6px;
        }
    }

    .search-box {
        background: #fff;

        @include linear-gradient(#eee, #fff);
        // border-bottom: 1px solid #ccc;
        // box-shadow: 0 0 5px rgba($color: #000000, $alpha: 0.9);
        border: 0;
        position: fixed;
        top: 60px;
        left: 0px;
        right: 0px;
        z-index: 19;
        padding: 10px;
        padding-right: 15px;
        padding-bottom: 5px;

        input,
        select {
            height: 30px;
            border-radius: 20px;
            padding-left: 36px;
        }

        .deliver-type-wrapper {
            width: 100%;
            margin-bottom: 10px;

            .button {
                width: 50%;
            }
        }

        .deliver-time-wrapper {
            .column {
                padding: 0 10px;

                .select:not(.is-multiple):not(.is-loading)::after {
                    border-color: $main-color;
                }

                label {
                    font-size: 1em;
                    position: relative;
                    top: 4px;
                }

                .select {
                    height: 30px;
                }

                select {
                    padding: 0 10px;
                    height: 30px;
                    line-height: 30px;
                }
            }
        }
    }

    .product-wrapper {
        padding: 0 10px;
        width: calc(100% - 10px);

        .out-time-seperate {
            text-align: center;
            font-size: 1.6em;
            font-weight: bolder;
            width: 100%;
            color: $main-color;
            border-top: 1px dashed $main-color;
            padding: 10px;
        }

        .store-name-section {
            flex: none;
            font-size: 1.2em;
            width: 100%;
            padding: 10px 10px 0;
            color: #E1623B;
            background: #fff;
            border-top: 1px dashed #ffa352;
            position: -webkit-sticky;
            position: sticky;
            top: -10px;
            margin-top: 15px;
            z-index: 20;

            .sale-time {
                position: absolute;
                font-size: 0.8em;
                text-align: right;
                top: 12px;
                right: 5px;
            }
        }
    }

    // &.is-native {
    //     padding-top: 100px;

    //     .search-box {
    //         top: 0px;
    //     }

    //     .modal-content {
    //         margin-top: 0;
    //         padding: 20px 20px 120px;
    //     }
    // }
}

.product-togggle-tabs {
    width: 100%;
    margin-bottom: 20px;

    li {
        width: 50%;
        line-height: 20px;

        &.is-active {

            a {
                background-color: $main-color !important;
                border-color: $main-active-color !important;
            }
        }
    }
}